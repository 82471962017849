// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/background.png", import.meta.url);
// Module
var code = `<template>
    <require from="./my_qr_code.scss"></require>
    <section id="my_qr_code" class="flex-column">
        <div id="miniheader" class="flex-row miniheader">
            <img
              id="thumbnail"
              click.delegate="router.navigateBack()"
              src="${___HTML_LOADER_IMPORT_0___}"
            />
            <div class="flex-grow"></div>
            <h1 t="profile.my_qr_code"></h1>
            <div class="flex-grow"></div>
        </div>
        <div class="flex-grow"></div>
        <div class="canvas">
            <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!qrcode_canvas">
            <canvas id="qrcode_canvas" ref="qrcode_canvas"></canvas>
        </div>
        <div class="flex-grow"></div>
    </section>
</template>
`;
// Exports
export default code;