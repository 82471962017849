// Module
var code = `<template>
  <require from="./pin_code_confirmed.scss"></require>
  <require from="../../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="pin_code_confirmed" class="flex-column">
    <div id="header" class="flex-row">
    </div>
    <div class="flex-grow"></div>
    <div class="click" if.bind="!me.isFirstTime"  click.delegate="router.navigateToRoute('profile')">
      <animated-checkmark></animated-checkmark>
    </div>
    <div class="click" if.bind="me.isFirstTime" click.delegate="router.navigateToRoute('choose_type_account')"> 
      <animated-checkmark></animated-checkmark>
    </div>
    <div class="flex-grow"></div>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;