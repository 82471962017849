import { Router } from "aurelia-router";
import { EventAggregator } from "aurelia-event-aggregator";
import { FileUploadHttpClients } from "http_clients/FileUploadHttpClients";
import { AuthService } from "aurelia-auth";
import { autoinject } from "aurelia-framework";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { json } from "aurelia-fetch-client";
import { SponsorshipHttpClient } from "http_clients/SponsorshipHttpClient";
import { I18N } from "aurelia-i18n";
import { SingletonService } from "singleton";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { User } from "components/models/UserModel";
@connectTo()
@autoinject()
export class EditProfile {
  private isLoading = false;
  private isSaved = false;
  private formData: FormData;
  private me: User;
  private selectedFile;
  private myInput;
  private profile_id: string;
  private affiliate: string;
  private errorMessageDisplayName: string;
  private displayError: boolean = false;
  private iHaveImage: boolean = true;
  private focusHide: boolean = false;
  private copyPayMailAddresseEnabled: boolean = true;
  private shaking: boolean;
  private tooLong: boolean = false;
  private state: State;

  constructor(
    private ea: EventAggregator,
    private fileUploadHttpClients: FileUploadHttpClients,
    private userHttpClients: UsersHttpClients,
    private router: Router,
    private sponsorshipHttpClient: SponsorshipHttpClient,
    private singleton: SingletonService,
    private authService: AuthService,
    private i18n: I18N,
    private store: Store<State>
  ) {
  }

  async changeProfilePicture() {
    let httpRequest = await this.fileUploadHttpClients.fetch(
      "yapily/fileupload/",
      {
        method: "POST",
        body: this.formData,
      }
    );
    this.iHaveImage = true;
  }


  changeProfilePic() {
    this.myInput.click();
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  onSelectFile() {
    this.formData = new FormData();
    this.formData.append("upload", this.selectedFile[0]);
    this.uploadProfilePic();
  }

  async uploadProfilePic() {
    let httpResult = await this.fileUploadHttpClients.fetch("/fileupload/", {
      method: "POST",
      body: this.formData,
    });

    let isHttpOk = await checkResponseStatus(httpResult);
    let json_response = await isHttpOk.json();
    this.me.urlPic = json_response.url;
    this.iHaveImage = true;
    this.singleton.setProfilePic(this.me.urlPic);
    this.saveProfile();
    this.ea.publish("profilePicBeenChanged");
  }
  /* patch/save profile */
  async saveProfile() {
    this.errorMessageDisplayName = "";
    this.displayError = false;
    this.isLoading = true;
  

    try {     
      this.isLoading = false;
      this.ea.publish('profile.update', this.me);
      this.router.navigateToRoute("profile"); //IF ERROR THEN DONT GO TO PROFILE
    } catch (error) {
      this.handleErrors(error);
    }
  }

  stateChanged(newState, oldState) {
    this.me = newState.me;
  }

  handleErrors({ errors }) {
    for (let error of errors.msg) {
      if (error.param == "displayName") {
        if (error.msg == "IS_EMPTY") {
          this.errorMessageDisplayName = this.i18n.tr("profile.edit_profile.edit_profile_errorMessageDisplayNameIsEmpty");
          this.displayError = true;
          this.isLoading = false;
        }
      }
    }
  }

  async sendEmailToMyFriend() {
    let httpRequest = await this.sponsorshipHttpClient.fetch("/sponsorships/", {
      method: "POST",
      body: json({ affiliate: this.affiliate }),
    });
    let is200ok = await checkResponseStatus(httpRequest);
    let response = await is200ok.json();
  }

  copyPayMailAddresse() {
    navigator.clipboard.writeText(this.me.paymail);
    this.copyPayMailAddresseEnabled = false;
    setTimeout(() => {
      this.copyPayMailAddresseEnabled = true;
    }, 1000);
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }
  goToMail() {
    this.router.navigateToRoute("verify_mail");
  }

  deleteAccount() {
    this.router.navigateToRoute("profile_delete");
  }
}


