// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/eraser.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./pin_code_create.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="pin_code_create" class="flex-column">
    <div id="header" class="flex-row">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
      <h1 i18n="profile.pin_code.title">PIN Code</h1>
    </div>
    <div class="flex-grow"></div>
    <div class="keypad_container flex-column" class.bind="shaking ? 'shake' : ''" >

      <!--FIRST TIME-->
      <div id="first_time" if.bind="firstCodePin == ''">
        <h3 i18n="profile.pin_code.pin_code_create"></h3>
        <div class="up_to_six flex-row">
          <template repeat.for="i of 6">
            <div class="square" if.bind="codePin.length <= (i)" class.bind="codePin.length > i ? 'used' : ''">
              <p>\${codePin[i]}</p>
            </div>
            <div class="square" if.bind="codePin.length > (i)">
              <p>*</p>
            </div>
          </template>
        </div> 
      </div>

      <!-- SECOND TIME -->
      <div id="second_time" if.bind="secondCodePin == '' && firstCodePin != ''">
        <div class="keypad_container flex-column" class.bind="shaking ? 'shake' : ''">
          <h3 i18n="profile.pin_code.pin_code_create_again"></h3>
          <div class="up_to_six flex-row">
            <template repeat.for="i of 6">
              <div class="square" if.bind="codePin.length <= (i)" class.bind="codePin.length > i ? 'used' : ''">
                <p>\${codePin[i]}</p>
              </div>
              <div class="square" if.bind="codePin.length > (i)">
                <p>*</p>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- KEYPAD -->
      <div class="flex-grow"></div>
      <div class="keypad">
        <button class="number_container" repeat.for="num of numberButtons" click.delegate="appendToInput(num)">
          <span>\${num}</span>
        </button>
        <button class="number_container no_bg">
        </button>
        <button class="number_container" click.delegate="appendToInput(0)">
          <span>0</span>
        </button>
        <button class="number_container dark" click.delegate="eraseFromInput()">
          <img src="${___HTML_LOADER_IMPORT_1___}"/>
        </button>
      </div>
    </div>
    <div class="flex-grow" if.bind="displayError"></div>
    <div class="error" class.bind="displayError ? 'shake' : ''" if.bind="displayError">
      <span> \${errorMessage} </span>
    </div>
    <div class="flex-grow"></div>
    <button id="profile.pin_code.create.button.sendCodePin" class="btn btn-primary" click.delegate="sendCodePin()" if.bind="codePin.length > 5 && !state.me.isFirstTime">
       <loader-ring class="loader-btn" if.bind="loading && codePin.length > 5"></loader-ring>
      <span if.bind="!loading" i18n="profile.pin_code.choose_pin"></span>
    </button>
    <div class="spacer-default" if.bind="!state.me.isFirstTime"></div>
  </section>
</template>
`;
// Exports
export default code;