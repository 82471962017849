import * as QRCode from "qrcode";
import { Router } from "aurelia-router";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { default as env } from "../../../../config/environment.json";
import { SingletonService } from "singleton";
import { AuthService } from "aurelia-auth";
import { User } from "components/models/UserModel";
import { connectTo } from "aurelia-store";
import { State } from "state";

@connectTo()
@autoinject()
export class myQrCode {
  private qrcode_canvas;
  private me: User;
  private friendId: string;
  private showQRCode: boolean = false;
  private params;

  constructor(
    private router: Router,
    private authService: AuthService,
    private singleton: SingletonService,
    private state: State
  ) {}

  activate(params) {
    this.params = params;
    this.friendId = params.friendId;
  }
  
  bind() {
    this.me = this.state.me
    this.setQRCode(this.params);
  }

  setQRCode(params) {
    QRCode.toCanvas(
      this.qrcode_canvas,
      env.front_front + "/contacts/mine?friendEmail=" + this.me.email,
      {
        color: {
          dark: "#0000",
          light: "#dfe0e2",
        },
      },
      (error: any) => {
        if (error) {
          console.log(error);
        } else {
          // QR code generation successful
          this.showQRCode = !this.showQRCode;
        }
      }
    );
  }

  backToProfile() {
    this.router.navigateToRoute("profile");
  }
}
