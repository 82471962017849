import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { AuthService } from 'aurelia-auth';
import { Router } from 'aurelia-router';
import { User } from 'components/models/UserModel';
import { SingletonService } from 'singleton';
import { connectTo } from 'aurelia-store';
import { State } from 'state';

@connectTo()
@autoinject
export class PinCodeConfirmed {
  private me: User;
  constructor(
    private router : Router,
    private singleton: SingletonService,
    private authService: AuthService,
    private state: State
    ) {}

  attached(){
  }
  bind(){
    this.me = this.state.me
    if (this.state.me.isFirstTime) {
      setTimeout(() => this.router.navigateToRoute("choose_type_account"), 3000);
    }
    else {
      setTimeout(() => this.router.navigateToRoute("profile"), 3000);
    }
  }
}
