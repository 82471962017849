// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/eraser.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./pin_code_actual.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="pin_code_actual" class="flex-column">
    <div id="header" class="flex-row">
      <img class="phone" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}" />
      <h1 t="profile.pin_code.title"></h1>
    </div>
    <div class="keypad_container flex-column">
      
      <!-- CURRENT STEP -->
      <div id="current_code" if.bind="!hasOldCodePin">
        <h3  i18n="profile.pin_code.pin_code_current"></h3>
        <div class="up_to_six flex-row">
          <template repeat.for="i of 6">
            <div class="square" if.bind="oldCodePin.length <= (i+1)" class.bind="oldCodePin.length > i ? 'used' : ''">
              <p>\${oldCodePin[i]}</p>
            </div>
            <div class="square" if.bind="oldCodePin.length > (i+1)">
              <p>*</p>
            </div>
          </template>
        </div> 
      </div>

      <!-- NEW PIN CODE -->
      <div id="new_pin_code" if.bind="hasOldCodePin && !confirmNewCodePin">
        <h3  i18n="profile.pin_code.pin_code_create"></h3>
        <div class="up_to_six flex-row">
          <template repeat.for="i of 6">
            <div class="square" if.bind="codePin.length <= (i+1)" class.bind="codePin.length > i ? 'used' : ''">
              <p>\${codePin[i]}</p>
            </div>
            <div class="square" if.bind="codePin.length > (i+1)">
              <p>*</p>
            </div>
          </template>
        </div>   
      </div>

      <!-- CONFIRM NEW CODE -->
      <div id="confirm_new_pin_code" if.bind="hasOldCodePin && confirmNewCodePin">
        <h3 i18n="profile.pin_code.pin_code_actual2"></h3>
        <div class="up_to_six flex-row">
          <template repeat.for="i of 6">
            <div class="square" if.bind="newCodePin.length <= (i+1)" class.bind="newCodePin.length > i ? 'used' : ''">
              <p>\${newCodePin[i]}</p>
            </div>
            <div class="square" if.bind="newCodePin.length > (i+1)">
              <p>*</p>
            </div>
          </template>
        </div>        
      </div>

      <div class="flex-grow"></div>
      <div class="keypad">
        <button class="number_container" repeat.for="num of numberButtons" click.delegate="appendToInput(num)">
          <span>\${num}</span>
        </button>
        <button class="number_container no_bg">
          <!-- <i class="fas fa-fingerprint"></i> -->
        </button>
        <button class="number_container" click.delegate="appendToInput(0)">
          <span>0</span>
        </button>
        <button class="number_container dark" click.delegate="eraseFromInput()">
          <img src="${___HTML_LOADER_IMPORT_1___}"/>
        </button>
      </div>
    </div>
    <div class="flex-grow" if.bind="displayError"></div>
    <div class="flex-grow"></div>
    <div class="animation_new_keypad button_holder flex-column">
      <button if.bind="hasOldCodePin && !confirmNewCodePin && !displayError" class="btn btn-primary" click.delegate="nextConfirm()">
        <loader-ring class="loader-btn" if.bind="!loading"></loader-ring>
        <span if.bind="loading" i18n="home.next"></span>
      </button>
      <button if.bind="confirmNewCodePin && !displayError" class="btn btn-primary" click.delegate="confirmPatchPin()">
        <loader-ring class="loader-btn" if.bind="!loading"></loader-ring>
        <span i18n="profile.pin_code.pin_code_actual2" if.bind="loading"></span>
      </button>
      <button if.bind="displayError" class.bind="displayError ? 'shake' : ''" class="btn btn-red">
        <span>\${errorMessage}</span>
      </button>
    </div>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;