// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/close_cross.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./notifications.scss"></require>
  <require from="../../../components/models/TimeAgoValueConverter"></require>
  <require from="../../../components/header-titles/header-title-notifications/header-title-notifications"></require>
  <section id="notifications" class="flex-column">
    <header-title-notifications menu-items.bind="listMenuItems" title.bind="title" subtitle.bind="subtitle" tabs.two-way="tabs">
    </header-title-notifications>
    <div class="contact flex-row" repeat.for="notification of state.notifications"
      class.bind="seen = true ? 'seen' : ''">
      <img class="profilePic" src.bind="notification.url_pic_fk" />
      <p class="name">
        <span class="title" if.bind="notification.notification === 'FRIEND_REQUEST'">
          \${notification.name_fk} \${i18n.tr('profile.notifications.contact_want_to_be_friend')}
        </span>
        <span class="date">
          \${notification.createdAt | timeAgo:localeForCurrency }
        </span>
      </p>
      <div class="flex-grow"></div>
      <img class="action" src="${___HTML_LOADER_IMPORT_0___}"/>
    </div>
  </section>
</template>
`;
// Exports
export default code;