import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { AuthService } from "aurelia-auth";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import { User } from "components/models/UserModel";
import { SingletonService } from "singleton";
import { connectTo } from "aurelia-store";
import { EventAggregator } from "aurelia-event-aggregator";

@connectTo()
@autoinject
export class PinCodeCreate {
  private firstCodePin: string = '';
  private secondCodePin: string = '';
  private codePin: string = '';
  private inputField;
  private me: User

  private displayError = false;
  private errorMessage: string;
  private shaking: boolean;$
  private loading: boolean;
  private isAppending: boolean = false;
  numberButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9];


  constructor(
    private singleton: SingletonService,
    private usersHttpClient: UsersHttpClients,
    private authService: AuthService,
    private i18n: I18N,
    private router: Router,
    private ea: EventAggregator,
  ) {}

  async sendCodePin() {
    this.loading = true;
    if (this.firstCodePin == '') {
      this.firstCodePin = this.codePin;
      this.codePin = '';
      this.loading = false;
    }
    else if (this.secondCodePin == '') {
      this.secondCodePin = this.codePin;

      if (this.firstCodePin == this.secondCodePin) {

        try{
          let httpRequest = await this.usersHttpClient.fetch("/auth/pin", {
            method: "POST",
            body: JSON.stringify({
              codePin: this.codePin,
            }),
          });
          let is200OK = await checkResponseStatus(httpRequest);
          let reponse: User = await is200OK.json();
          this.ea.publish('profile.updated', reponse);
          this.router.navigateToRoute('pin_code_confirmed')
          this.loading = false;
        }catch(err){
          this.loading = false;
          this.displayError = true;
          this.errorMessage = this.i18n.tr("home.error.error_global");
        }
      }
      else {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.error_pin_code_dont_match"); //error: first and second code pin don't match.
        
        this.firstCodePin = '';
        this.secondCodePin = '';
        this.codePin = '';
        this.loading = false;
      }
    }
  }

  appendToInput(num: number) {
    if (this.isAppending) {
      return;
    }

    this.isAppending = true;
    this.singleton.setCurrentAccountIndex(0);
    this.singleton.setCurrentBankIndex(0);
    if (this.codePin.length > 5) {
      this.isAppending = false;
      return;
    }

    if (this.codePin.length < 6) {
      this.codePin = this.codePin + num;
    }

    if (this.codePin.length === 6) {
      this.sendCodePin();
    }

    setTimeout(() => {
      this.isAppending = false;
    }, 50);
  }
  
  eraseFromInput() {
    this.displayError = false;
    this.codePin = this.codePin.slice(0, -1);
  }
}
