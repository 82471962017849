import { computedFrom } from 'aurelia-framework';
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { AuthService } from "aurelia-auth";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { SingletonService } from "singleton";
import { I18N } from "aurelia-i18n";

@autoinject
export class PinCode {
  private codePin: string = "";
  private inputField;
  private number: number = 0;
  private password: string = "codepin";
  private loading: boolean = false;
  private displayError = false;
  private errorMessage: string;
  private shaking: boolean;
  private isAppending = false;

  constructor(
    private usersHttpClient: UsersHttpClients,
    private router: Router,
    private authService: AuthService,
    private singleton: SingletonService,
    private i18n: I18N
  ) {}

  appendToInput(num: number) {
    if (this.isAppending) {
      return;
    }

    this.isAppending = true;
    this.singleton.setCurrentAccountIndex(0);
    this.singleton.setCurrentBankIndex(0);
    if (this.codePin.length > 5) {
      this.isAppending = false;
      return;
    }

    this.codePin = this.codePin + num;

    if (this.codePin.length === 6) {
      this.login();
    }

    setTimeout(() => {
      this.isAppending = false;
    }, 50);
  }

  async login() {
    this.singleton.setCurrentAccountIndex(0);
    this.singleton.setCurrentBankIndex(0);
    return this.authService
      .login(this.singleton.getEmail(), this.codePin)
      .then(async (response) => {
        this.singleton.setMe(response.user);
        await this.singleton.setMeDB(response.user);
      })
      .catch((err) => {
        console.log(err);
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.error_global");
        /* 409 => incorrect pin*/
        if (err.status == 409) this.errorMessage = this.i18n.tr("home.error.error_badpin")
        this.loading = false;
        this.codePin = "";
      });
  }

  eraseFromInput() {
    this.displayError = false;
    this.codePin = this.codePin.slice(0, -1);
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  @computedFrom("codePin")
  get firstNumber(): string {
    if (this.codePin && this.codePin.length >= 1) {
      return this.codePin.charAt(0);
    }
    return '';
  }

  @computedFrom("codePin")
  get secondNumber(): string {
    if (this.codePin && this.codePin.length >= 2) {
      return this.codePin.charAt(1);
    }
    return '';
  }

  @computedFrom("codePin")
  get thirdNumber(): string {
    if (this.codePin && this.codePin.length >= 3) {
      return this.codePin.charAt(2);
    }
    return '';
  }

  @computedFrom("codePin")
  get fourthNumber(): string {
    if (this.codePin && this.codePin.length >= 4) {
      return this.codePin.charAt(3);
    }
    return '';
  }

  @computedFrom("codePin")
  get fifthNumber(): string {
    if (this.codePin && this.codePin.length >= 5) {
      return this.codePin.charAt(4);
    }
    return '';
  }

  @computedFrom("codePin")
  get sixthNumber(): string {
    if (this.codePin && this.codePin.length >= 6) {
      return this.codePin.charAt(5);
    }
    return '';
  }
}
