// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/eraser.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./pin_code.scss"></require>
  <section id="pin_code" class="flex-column">
    <div id="header" class="flex-row">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
      <h1 i18n="profile.pin_code.title"></h1>
    </div>
    <div class="keypad_container flex-column">
      <div class="flex-grow"></div>
      <h3 i18n="profile.pin_code.pin_code"></h3>
      <div class="flex-grow"></div>
      <div class="up_to_six flex-row">
        <div class="square" if.bind="codePin.length < 2" class.bind="codePin.length >= 1 ? 'used' : ''">
          <p>\${firstNumber}</p>
        </div>
        <div class="square" if.bind="codePin.length >= 2">
          <p>*</p>
        </div>
        <div class="square" if.bind="codePin.length < 3" class.bind="codePin.length >= 2 ? 'used' : ''">
          <p>\${secondNumber}</p>
        </div>
        <div class="square" if.bind="codePin.length >= 3">
          <p>*</p>
        </div>
        <div class="square" if.bind="codePin.length < 4"class.bind="codePin.length >= 3 ? 'used' : ''">
          <p>\${thirdNumber}</p>
        </div>
        <div class="square" if.bind="codePin.length >= 4">
          <p>*</p>
        </div>
        <div class="square" if.bind="codePin.length < 5"class.bind="codePin.length >= 4 ? 'used' : ''">
          <p>\${fourthNumber}</p>
        </div>
        <div class="square" if.bind="codePin.length >= 5">
          <p>*</p>
        </div>
        <div class="square" if.bind="codePin.length < 6 "class.bind="codePin.length >= 5 ? 'used' : ''">
          <p>\${fifthNumber}</p>
        </div>
        <div class="square" if.bind="codePin.length >= 6">
          <p>*</p>
        </div>
        <div class="square" if.bind="codePin.length < 6" class.bind="codePin.length >= 6 ? 'used' : ''">
          <p>\${sixthNumber}</p>
        </div>
      </div>
      <div class="flex-grow"></div>
      <div class="keypad">
        <button id="profile.pin_code.button.1" class="number_container" click.delegate="appendToInput(1)">
          <span>1</span>
        </button>
        <button id="profile.pin_code.button.2" class="number_container" click.delegate="appendToInput(2)">
          <span>2</span>
        </button>
        <button id="profile.pin_code.button.3" class="number_container" click.delegate="appendToInput(3)">
          <span>3</span>
        </button>
        <button id="profile.pin_code.button.4" class="number_container" click.delegate="appendToInput(4)">
          <span>4</span>
        </button>
        <button id="profile.pin_code.button.5" class="number_container" click.delegate="appendToInput(5)">
          <span>5</span>
        </button>
        <button id="profile.pin_code.button.6" class="number_container" click.delegate="appendToInput(6)">
          <span>6</span>
        </button>
        <button id="profile.pin_code.button.7" class="number_container" click.delegate="appendToInput(7)">
          <span>7</span>
        </button>
        <button id="profile.pin_code.button.8" class="number_container" click.delegate="appendToInput(8)">
          <span>8</span>
        </button>
        <button id="profile.pin_code.button.9" class="number_container" click.delegate="appendToInput(9)">
          <span>9</span>
        </button>
        <button id="profile.pin_code.button.number" 
          class="number_container no_bg">
        </button>
        <button id="profile.pin_code.button.0" class="number_container" click.delegate="appendToInput(0)">
          <span>0</span>
        </button>
        <button id="profile.pin_code.button.eraseFromInput" class="number_container dark" click.delegate="eraseFromInput()">
          <img src="${___HTML_LOADER_IMPORT_1___}"/>
        </button>
      </div>
    </div>
    <div class="flex-grow" if.bind="displayError"></div>
    <div class="error" class.bind="displayError ? 'shake' : ''" if.bind="displayError">
      <span> \${errorMessage} </span>
    </div>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;