// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/logo_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/phone2.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./edit_profile.scss"></require>
  <require from="../../../components/phone_country/phone_country"></require>
  <require from="../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="edit_profile" class="flex-column">
    <div id="miniheader" class="flex-row miniheader">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
    </div>
    <div class="flex-grow dishid"></div>
    <div class="nameAndPic flex-column" if.bind="!focusHide">
      <img
        if.bind="iHaveImage"
        src="\${ me.urlPic }"
        click.delegate="changeProfilePic()"
      />
      <img
        if.bind="!iHaveImage"
        src="${___HTML_LOADER_IMPORT_1___}"
        click.delegate="changeProfilePic()"
      />
      <div class="rond_bleu flex-column" click.delegate="changeProfilePic()">
        <i class="fas fa-pen right"></i>
      </div>
    </div>
    <input
      id="profile.edit.image"
      ref="myInput"
      type="file"
      files.bind="selectedFile"
      accept="image/*"
      change.delegate="onSelectFile()"
      style="display: none"
    />
    <form class="inputs" id="userInfos" class.bind="shaking ? 'shake' : ''">
      <div class="input-container">
        <input
          id="profile.edit.displayName"
          placeholder="\${ me.displayName}"
          type="displayName"
          i18n="[placeholder]profile.edit_profile.displayName"
          value.bind="me.displayName"
          maxlength="30"
        />
        <i class="fa fa-user icon"></i>
      </div>
      <div class="input-container" id="phone">
        <phone-country></phone-country>
        <input
          id="profile.edit.number"
          type="number"
          value.bind="me.phone"
          i18n="[placeholder]profile.edit_profile.phone_title"
          maxlength="15"
          onkeypress="return /[0-9]/i.test(event.key)"
          required
        />
        <i class="fa icon">
          <img 
          src="${___HTML_LOADER_IMPORT_2___}" 
          alt="telephone icon" width="30" height="25">
        </img>
        </i>
      </div>
      <div class="input-container" id="disabledInput">
        <input
          id="profile.edit.email"
          disabled
          placeholder="\${me.email}"
          type="email"
          value.bind="me.email"
        />
        <i class="fa fa-at icon"></i>
      </div>
    </form>
    <div
      class="verify btn btn-secondary flex-row"
      click.delegate="goToMail()"
      if.bind="me.verified==false"
    >
      <i class="fas fa-exclamation-triangle"></i>
      <a class="verify_your_mail" id="verify_mail" t="profile.edit_profile.verify"></a>
    </div>
    <div class="profile_changes flex-row" if.bind="isSaved == true">
      <p class="green" t="profile.edit_profile.change_successful"></p>
      <animated_checkmark if.bind="isSaved"></animated_checkmark>
    </div>
    <div
      class="error"
      class.bind="displayError ? 'shake' : ''"
      if.bind="displayError"
      style="background: none; flex: 1"
    >
      <span> \${errorMessageDisplayName} </span>
    </div>
    <button
      click.delegate="saveProfile()"
      class="btn btn-primary validate"
      disabled.bind="isLoading"
    >
      <span t="profile.edit_profile.validate" if.bind="!isLoading"></span>
      <i class="fas fa-spinner fa-pulse" if.bind="isLoading"></i>
    </button>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;