import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { SingletonService } from "singleton";
import { I18N } from "aurelia-i18n";
import { NotificationHttpClient } from "http_clients/NotificationHttpClient";
import { User } from "components/models/UserModel";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { NotificationModel } from "components/models/NotificationModel";
import { connectTo } from "aurelia-store";
import { EventAggregator } from "aurelia-event-aggregator";
import { MenuItem } from "components/models/MenuItem";

@connectTo()
@autoinject
export class ContactsNotification {
  private allNotificationInformation: NotificationModel
  private name: string;
  private swipe: boolean = false;
  private draggableDiv: HTMLDivElement[] = [];
  private test = ["1","2"];
  private localeForCurrency = "fr-FR";
  private listMenuItems: Array<MenuItem> = [];
  private title: string;
  private subtitle: string;

  constructor(
    private router: Router,
    private i18n: I18N,
    private notificationHttpClient: NotificationHttpClient,
    private eventAggregator: EventAggregator) 
  {
    this.getLocaleForCurrency();
  }

  getLocaleForCurrency() {
    const currentLocale = this.i18n.getLocale();
    switch (currentLocale) {
      case "fr":
        this.localeForCurrency = "fr-FR"; // 1 000 325,23
        break;
      case "de":
        this.localeForCurrency = "de-DE";
        break;
      case "en":
        this.localeForCurrency = "en-US"; // 1,000,325.23$
        break;
      default:
        this.localeForCurrency = "fr-FR";
        break;
    }
  }

  async goToContact() {
      this.router.navigateToRoute('contacts')
  }

  // When quitting this page we will set all current unread notifications
  // as read.
  deactivate() {
    this.eventAggregator.publish("notifications.set.all.read");
  }

  goToHome() {
      this.router.navigateToRoute('home')
  }

  fillMenuItems(newState) {
    let nbrReadNotifications = newState.notifications.filter(notification => notification.seen).length || 0; 
    let nbrUnreadNotifications = newState.notifications.filter(notification => !notification.seen).length || 0;
    const nbrTotalNottifications = newState.notifications.length || 0;
    const allAccountsMenuItem = new MenuItem(
      this.i18n.tr("home.all"),
      "",
      nbrTotalNottifications || 0
    );
    const bankAccountsMenuItem = new MenuItem(
      this.i18n.tr("profile.notifications.unread"), //unread notifs
      "",
      nbrUnreadNotifications || 0
    );
    const bitcoinWalletsMenuItem = new MenuItem(
      this.i18n.tr("profile.notifications.read"), //read notifs
      "",
      nbrReadNotifications || 0
    );
    this.listMenuItems = [];  
    this.listMenuItems.push(allAccountsMenuItem);
    this.listMenuItems.push(bankAccountsMenuItem);
    this.listMenuItems.push(bitcoinWalletsMenuItem);
    this.title = this.i18n.tr("profile.notifications.title");
    this.subtitle = this.i18n.tr("home.welcome_back");
  }

  stateChanged(newState, oldState) {
    this.fillMenuItems(newState);
  }
}
