import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { autoinject } from "aurelia-dependency-injection";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { AuthService } from "aurelia-auth";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import { json } from "aurelia-fetch-client";

@autoinject
export class PinCodeActual {
  private codePin: string = "";
  private hasOldCodePin: boolean = false;
  private oldCodePin: string = "";
  private confirmNewCodePin: boolean = false;
  private newCodePin: string = "";
  numberButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  private displayError = false;
  private errorMessage: string;
  private shaking: boolean;
  private me;
  private isAppending: boolean = false;
  private debounceTimer: NodeJS.Timeout | null = null;


  constructor(
    private usersHttpClient: UsersHttpClients,
    private authService: AuthService,
    private i18n: I18N,
    private router: Router,
    private userHttpClient: UsersHttpClients,
    private singleton: SingletonService,
    private loading: boolean = false
  ) {
    this.me = this.singleton.getMe();
  }

  appendToInput(num: number) {
    if (this.isAppending) {
      return;
    }

    this.isAppending = true;
    clearTimeout(this.debounceTimer);
    if (this.hasOldCodePin === false) {
      if (this.oldCodePin.length > 5) {
        this.isAppending = false;
        return;
      }

      this.oldCodePin = this.oldCodePin + num;
    } else {
      if (this.confirmNewCodePin === false){
        if (this.codePin.length > 5) {
          this.isAppending = false;
          return;
        }
        this.codePin = this.codePin + num;
      } else {
        if (this.newCodePin.length > 5) {
          this.isAppending = false;
          return;
        }
        this.newCodePin = this.newCodePin + num;
      }
    }

    if (this.oldCodePin.length === 6) {
      this.confirmOldCodePin();
      this.isAppending = false;
      return;
    }

    if (this.codePin.length === 6) {
      this.nextConfirm();
      this.isAppending = false;
      return;
    }

    this.debounceTimer = setTimeout(() => {
      this.isAppending = false;
    }, 50);
  }

  eraseFromInput() {
    if (this.hasOldCodePin == false) {
      this.displayError = false;
      this.oldCodePin = this.oldCodePin.slice(0, -1);
    } else {
        this.displayError = false;
        if (this.confirmNewCodePin == false) {
          this.codePin = this.codePin.slice(0, -1);
        } else if (this.confirmNewCodePin == true) {
          this.displayError = false;
          this.newCodePin = this.newCodePin.slice(0, -1);
        }
    }
  }

  async confirmOldCodePin() {
    this.loading = true;
    this.displayError = false;
    let httpRequest = await this.userHttpClient.fetch("/auth/login",
    {
      body: JSON.stringify({
        email: this.me.email,
        password: this.oldCodePin
      }),
      method: "POST"
    })
    try {
      let httpResponse = await checkResponseStatus(httpRequest);
      let response = await httpResponse.json();
      if(response.token !== "")
        this.hasOldCodePin = true;

    } catch(error) {
      this.loading = false;
      this.displayError = true;
      this.shakeError();
      this.errorMessage =   this.i18n.tr("home.error.error_pin_bad_pin");
      this.oldCodePin = ""
    }
  }

  async confirmPatchPin() {
    try{
      if (this.codePin === this.newCodePin) {
        this.loading = true;
        let request = await this.userHttpClient.fetch("/profile/changeCodePin", {
          method: "POST",
          body: json({
            oldCodePin: this.oldCodePin,
            codePin: this.codePin,
          }),
        });
        let is200OK = await checkResponseStatus(request);
        let reponse = await is200OK;
        this.router.navigateToRoute("pin_code_confirmed");
        this.loading = false;
      } else {
        this.loading = false;
        this.displayError = true;
        this.shakeError();
        this.errorMessage = this.i18n.tr("error.pin_code.no_pin_match");
      }     
    }catch(err){
      this.loading = false;
      this.displayError = true;
      this.shakeError();
      this.errorMessage = this.i18n.tr("home.error.error_pin_bad_pin");
      if (err.msg[0].msg == "CODE_PIN_TOO_SHORT_MIN_6") this.errorMessage = this.i18n.tr("home.error.error_pin_too_short"); 
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  goBack() {
    this.router.navigateBack();
  }

  nextConfirm() {
    if (this.codePin.length === 6) {
      this.confirmNewCodePin = true;
    } else {
      this.loading = false;
      this.displayError = true;
      this.shakeError();
      this.errorMessage = this.i18n.tr("home.error.error_pin_too_short")
    }
  }

  nextConfirmPINcode() {
    if (this.codePin === this.newCodePin) {
      this.confirmNewCodePin = false;
    }
    this.confirmNewCodePin = true;
  }
}